.Header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: #e79600a3;
  color: #333;
}

.Header-logo {
  font-weight: bold;
  margin: 12px 5px 0 10px;
  width: 90px;
}

.Header-help {
  margin: 20px 20px 0px 5px;
  font-size: 0.8em;
  font-style: italic;
}
