.Preview {
  position: absolute;
  top: 60px;
  left: 70px;
  right: 0;
  bottom: 0;
  background: #2e2e2e;
  transform: translateX(-100%);
  transition: 0.2s transform;
  margin-bottom: 0;
  overflow: auto;
  color: #fff;
}

.Preview-expanded {
  z-index: 1;
  transform: translateX(0);
  transition: 0.2s transform;
  min-height: 100vh;
}

.Preview-muted {
  opacity: 0.2;
  pointer-events: none;
}

.Preview-scrubber {
  text-align: center;
  width: 340px;
  margin: 1rem auto;
}

.Preview-scrubber input {
  width: 300px;
  cursor: pointer;
}

.Preview-scrubber-counter {
  text-align: center;
  color: #fff;
  font-weight: bold;
  width: 200px;
  margin: 0 auto;
}

.Preview-create {
  width: 200px;
  text-align: center;
  margin: 20px auto 0;
}

.Preview-create-button {
  width: 210px;
  height: 35px;
  margin-top: 10px;
  font-size: 1em;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  outline: none !important;
  animation: fade-in 0.5s;
}

.Preview-create-button:hover {
  background: #484848;
}

.Preview-create-button:active {
  background: #3b3b3b;
}

.Preview-create-button:focus {
  border-color: #e79600;
  background: #484848;
}

.Preview-progress-outer {
  width: 210px;
  margin: 15px auto 0;
}

.Preview-progress-inner {
  height: 100%;
  border-radius: 3px;
}

.Preview-progress-success {
  width: 200px;
  margin: 15px auto 40px;
  text-align: center;
  color: #fff;
}

.Preview-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Preview-no-frames {
  width: 360px;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  font-weight: bold;
}
