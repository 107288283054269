.HelpModal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0, 0.75);
}

.HelpModal-content {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  height: 400px;
  color: #fff;
  font-family: arial;
  font-size: 14px;
  position: fixed;
  border-radius: 0.25rem;
}

.HelpModal-text {
  height: 80%;
  overflow: scroll;
}

.HelpModal-header h1 {
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.HelpModal.show {
  display: block;
}

.HelpModal.hide {
  display: none;
}

.HelpModal-close-btn {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.5);
}

.HelpModal-close-btn:hover {
  cursor: pointer;
  color: #fff;
}
