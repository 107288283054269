.calculator {
  position: absolute;
  top: 60px;
  right: 0;
  bottom: 0;
  min-height: 100vh;
}

.calculator-burst,
.calculator-settings {
  left: 271px;
}

.calculator-none,
.calculator-preview {
  left: 70px;
}

.calculator-files {
  left: 449px;
}
