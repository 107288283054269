.GenerateGifForm-container {
  min-height: 100vh;
}

.GenerateGifForm-form {
  display: inline-block;
  margin-bottom: 40px;
}

.GenerateGifForm-input {
  display: block;
  height: 20px;
  width: 200px;
  margin: 15px auto 0;
  border: 1px solid #000;
  font-size: 1em;
  border-radius: 5px;
  padding: 5px;
  outline: none !important;
  text-align: center;
}

.GenerateGifForm-select {
  width: 210px;
  margin: 15px auto 0;
  border: none;
  height: 33px;
  font-size: 1em;
  text-align: center;
}

.GenerateGifForm-input-error {
  border-color: #cc0000;
}

.GenerateGifForm-button {
  width: 210px;
  height: 35px;
  margin-top: 15px;
  font-size: 1em;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  animation: fade-in 0.5s;
}

.GenerateGifForm-button:hover {
  background: #484848;
}

.GenerateGifForm-button:active {
  background: #3b3b3b;
}

.GenerateGifForm-button:focus {
  border-color: #e79600;
  background: #484848;
}

.ColorPicker {
  display: block;
  width: 210px;
  margin: 15px auto 0;
  color: #fff;
  font-size: 1em;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  border: none;
}

.ColorPicker:hover {
  cursor: pointer;
}

.ColorPicker-SketchPicker {
  display: inline-block;
  margin: 15px auto 0;
  font-size: 1em;
  padding: 5px;
  text-align: center;
}
