.Folder {
  position: absolute;
  top: 60px;
  left: 70px;
  bottom: 0;
  width: 378px;
  background: #2e2e2e;
  border-right: 1px solid black;
  text-align: center;
  transform: translateX(-100%);
  transition: 0.2s transform;
  color: #fff;
  min-height: 100vh;
}

.Folder-expanded {
  transform: translateX(0);
  transition: 0.2s transform;
}

.Folder-input {
  height: 20px;
  width: 150px;
  margin-bottom: 15px;
  margin-top: 5px;
  border: 1px solid #000;
  font-size: 1em;
  border-radius: 5px;
  padding: 5px;
  outline: none !important;
  text-align: start;
}

.Folder-input-error {
  border-color: #cc0000;
}

.Folder-saved-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}

.Folder-saved-item {
  position: relative;
  width: 100%;
  z-index: 1;
}

.Folder-saved-item:hover {
  cursor: pointer;
}

.Folder-delete-graph {
  border: transparent;
  position: absolute;
  top: 0px;
  right: 20px;
}

.Folder-saved-graph {
  display: flex;
  margin-bottom: 5px;
  padding-left: 45px;
  padding-right: 45px;
  text-align: start;
}

.Folder-saved-graph > img {
  border-radius: 5px;
}

.Folder-previous-items {
  margin-top: 15px;
}

.Folder-previous-items::-webkit-scrollbar {
  width: 0 !important;
}

.Folder-titles {
  padding-top: 30px;
}

.Folder-item-text {
  display: block;
  width: 225px;
  padding-left: 10px;
}

.Folder-name-text {
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Folder-small-text {
  font-size: 0.75rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Folder-button {
  background: transparent;
  color: #fff;
  cursor: pointer;
  outline: none !important;
  border-radius: 5px;
}

.Folder-save {
  border: 1px solid #fff;
  width: 110px;
  height: 35px;
  margin-top: 10px;
  font-size: 1em;
}

.Folder-delete-text {
  transform: rotate(45deg);
  font-size: 1.25rem;
}

.Folder-button:hover {
  background: #484848;
}

.Folder-button:active {
  background: #3b3b3b;
}

.Folder-button:focus {
  background: #484848;
  border-color: #e79600;
}

.Folder-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 448px) {
  .Folder {
    width: calc(100vw - 70px);
  }

  .Folder-name-text {
    width: 200px;
  }

  .Folder-small-text {
    width: 200px;
  }

  .Folder-saved-graph {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 390px) {
  .Folder {
    width: calc(100vw - 70px);
  }

  .Folder-name-text {
    width: 175px;
  }

  .Folder-small-text {
    width: 175px;
  }

  .Folder-saved-graph {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 365px) {
  .Folder {
    width: calc(100vw - 70px);
  }

  .Folder-name-text {
    width: 135px;
  }

  .Folder-small-text {
    width: 135px;
  }

  .Folder-saved-graph {
    padding-left: 20px;
    padding-right: 20px;
  }
}
